import React from "react";
import { stringFragmenter } from "../../utils/TextUtils";
import HelmetMetaData from "../HelmetMetaData";

function Meditation() {
  const MEDITATION_TEXT = `A meditáció  olyan folyamat, amelyen keresztül a teljes jelenlétig juthatunk el. A meditálást választók rövid távú célja lehet bizonyos negatív érzelmek feloldása, a múlt és jövő gondjainak elengedése, az elme zajával való azonosulás megszüntetése, az azon való felülemelkedés. A meditáció gyakorlásának hatása, hogy az ember kiegyensúlyozottabbá, felszabadultabbá és energikusabbá válik. Megőrzi lélekjelenlétét olyan helyzetekben is, amelyekben korábban nem volt képes.
  A meditáció hosszú távon a valódi önismeret eszköze. Az, ami valójában vagyok túlmutat a szavakon, ezért szavakkal teljesen leírni nem lehet. Megélni viszont igen.
  A meditálás természetes dolog. A figyelem tudatos alkalmazásán alapul. Egyszerű, könnyű megtanulni és gyakorolni még a hétköznapi életfeladataink ellátása mellett is, nem kell elvonulnunk a világtól egy hegytetőre.
  A bevezetést a meditálás gyakorlásába általában az ötödik szemeszterben ajánljuk fel hallgatóinknak, viszont korábban is tanulunk olyan technikát, amellyel a jelenlétet gyakorolhatjuk.
  A meditáló hallgatók részére rendszeres konzultációs lehetőséget biztosítunk, hogy kérdéseiket, tapasztalataikat megbeszélhessék gyakorlott meditálókkal, míg ők maguk is rutinos meditálókká nem válnak.
  Iskolánk a londoni School of Meditation szervezetével szoros együttműködésben támogatja a meditálás iránt érdeklődőket.`;

  const MEDITATION_SCHOOL_LINK = `https://www.schoolofmeditation.org/`;

  const mediTextWithParagraphs = stringFragmenter(MEDITATION_TEXT);

  const uniqueHelmetMetaData = {
    title: "Gyakorlati Filozófia Iskolája | Meditáció",
    description:
      "A Gyakorlati Filozófia Iskolájában használt meditációs technika leírása.",
  };

  return (
    <div className="page-wrapper">
      <HelmetMetaData {...uniqueHelmetMetaData} />
      <div className="info-wrapper">
        <h2>Meditáció</h2>
        <p>
          {mediTextWithParagraphs}
          <a
            href={MEDITATION_SCHOOL_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            School of Meditation London
          </a>
        </p>
      </div>
    </div>
  );
}
export default Meditation;
