import React from "react";
import { stringFragmenter } from "../../utils/TextUtils";
import HelmetMetaData from "../HelmetMetaData";

function History() {
  const OUR_HISTORY = `A Gyakorlati Filozófia Iskolája egy Magyarországon bejegyzett nonprofit, önkéntes alapon működő közhasznú alapítvány. Önálló szervezet, viszont egy nemzetközi hálózat része, amelynek a feje a londoni School of Philosophy and Economic Science. Ezt az elsőnek megalapított iskolát számos másik követte a világ számos pontján, Észak- és Dél-Amerikában, Ausztráliában, Európa több országában, sőt még Dél-Afrikában is.
  A londoni iskola eredetét a 1930-as évek nagy gazdasági válságában indult School of Economic Science-hez vezethetjük vissza. Alapítója Leon MacLaren a 19. századi amerikai közgazdász, Henry George tanaitól fellelkesedve azt remélte, hogy a társadalom igazságtalanságai a gazdasági törvények megértése által orvosolhatóak. Henry George alaptétele az, hogy az az emberé, amit maga teremt, de a természeti világ – és különösen a föld –, ahonnan minden emberi szükséglet kielégítése származik, az egész emberiség egyenjogú tulajdona. 
  Az iskola 1937-ben kezdett közgazdaságtani tanfolyamokat indítani. Idővel világossá vált, hogy a közgazdaság nem más, mint az emberek egymáshoz való viszonya a társadalomban, tehát megértéséhez magának az embernek a megértése szükséges. Ez a meglátás vezetett a filozófiához, „a bölcsesség szeretetéhez” (filo=szeretet+szofia=bölcsesség), azt remélték, segítségével mélyebb bepillantást nyerhetnek az emberiséget kormányzó természetes törvényekbe és ezek eredetébe.
  Az 1950-es évek elején kapcsolatba léptek a Study Society-vel és megismerkedtek Ouspensky és Gurdjieff tanításaival. Így a közgazdasági tanfolyamok mellett 1954-ben elindultak az első filozófiai kurzusok is.
  1959-ben Londonba érkezett Maharisi Mahes Yógi, aki a meditálás bevezetésével megteremtette a fejlődés következő szintjét.
  A ‘60-as évek közepén az iskola kapcsolatba került az Indiai Védanta hagyomány akkori nagy tanítójával, Srí Sántánanda Szaraszvatíval. Ezen a kapcsolaton keresztül az iskola a következő 30 évben felbecsülhetetlen értékű útmutatást kapott az advéta filozófia néven ismert egyetemes tanításhoz és a meditálás gyakorlásához. Az advéta szó jelentése nem-kettős (a = fosztó + dwaita = kettő). Srí Sántánanda1997-ben bekövetkezett halála óta a kapcsolatot és tanácsadást utódja, Vasudévananda Szaraszvatí nyújtja.
  A Filozófia Gyakorlati Iskoláját Budapesten 2003-ban alapította Beöthy Miklós.`;

  const historyWithParagraphs = stringFragmenter(OUR_HISTORY);

  const uniqueHelmetMetaData = {
    title: "Gyakorlati Filozófia Iskolája | Történetünk",
    description: "A Gyakorlati Filozófia Iskolája története.",
  };

  return (
    <div className="page-wrapper">
      <HelmetMetaData {...uniqueHelmetMetaData} />
      <div className="info-wrapper">
        <h2>Történetünk</h2>
        <p>{historyWithParagraphs}</p>
      </div>
    </div>
  );
}
export default History;
