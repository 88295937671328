import React from "react";
import { BASIC_INFO } from "../../resources/BasicInfo";
import { PRODUCTS_LIST } from "../../resources/ProductsInfo";
import ProductCard from "./ProductCard";
import HelmetMetaData from "../HelmetMetaData";

function Products() {
  const displayProducts = PRODUCTS_LIST.map((product, index) => (
    <ProductCard key={index} product={product} />
  ));

  const uniqueHelmetMetaData = {
    title: "Gyakorlati Filozófia Iskolája | Kiadványaink",
    description:
      "A Gyakorlati Filozófia Iskolája gondozásában megjelent könyvek, kiadványok.",
  };

  return (
    <div className="page-wrapper">
      <HelmetMetaData {...uniqueHelmetMetaData} />
      <div className="info-wrapper">
        <h2>Kiadványok</h2>
        <p>
          Az alábbi kiadványokat saját gondozásunkban jelentettük meg. Célunk,
          hogy olyan minőségi és magas szellemi értékeket tartalmazó könyveket
          adjunk ki, melyek a tudatosabb élet, illetve a spiritualitás
          iránt érdeklődők számára útmutatóként szolgálhatnak.
        </p>
        <p>
          A könyvek az alábbi email címen rendelhetők meg:{" "}
          <a href={`mailto:${BASIC_INFO.contactInfo[1].data}`}>
            {BASIC_INFO.contactInfo[1].data}
          </a>
        </p>
      </div>
      {displayProducts}
    </div>
  );
}
export default Products;
