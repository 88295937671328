import React from "react";
import { TUTORS_LIST } from "../../resources/TutorsInfo";
import TutorCard from "./TutorCard";
import "../../styles/global-styles.css";
import { stringFragmenter } from "../../utils/TextUtils";
import HelmetMetaData from "../HelmetMetaData";

function Tutors() {
  const TUTORS_INTRO = `A Gyakorlati Filozófia Iskolája valamennyi előadója egyben az iskola hallgatója is, akik már több éve tanulják a gyakorlati filozófiát. Személyes tapasztalatuk, hogy a filozófia működik – azaz a filozófiai bölcsesség alkalmazása korlátlan és valódi. A csoportok vezetésével és támogatásával azt az egyszerű vágyat követik, hogy elindítsák a hallgatókat az önfelfedezés útján, hogy eljuthassanak annak megtapasztalásához, hogy lehetséges az életet magasabb szintre emelni.
  Az iskola előadói és a csoportok mellett segítő asszisztensek nem kapnak fizetést a munkájukért, szolgálatukat az iskola és a közösség, tágabb értelemben a társadalom javára ajánlják fel. Személyes és szakmai hátterük pont olyan különböző, mint azoké a hallgatóké, akik mellett szolgálatot teljesítenek előadóként.
  „Nem taníthatsz meg senkinek semmit, csak abban segíthetsz, hogy felfedezzék saját válaszaikat.” Galileo Galilei (1564–1642) itáliai fizikus, csillagász, matematikus, természettudós`;

  const tutorsIntroParagraphs = stringFragmenter(TUTORS_INTRO);

  const displayTutors = TUTORS_LIST.map((tutor, index) => (
    <TutorCard key={index} tutor={tutor} />
  ));

  const uniqueHelmetMetaData = {
    title: "Gyakorlati Filozófia Iskolája | Előadóink",
    description: "A Gyakorlati Filozófia Iskolája előadói.",
  };

  return (
    <div className="page-wrapper">
      <HelmetMetaData {...uniqueHelmetMetaData} />
      <h2>Előadóink</h2>
      <div className="info-wrapper">
        <h3>Kik tanítanak a tanfolyamokon?</h3>
        <div className="divider-line"></div>
        <p>{tutorsIntroParagraphs}</p>
      </div>
      {displayTutors}
    </div>
  );
}
export default Tutors;
