export const BASIC_INFO = {
  orgFullName: "Gyakorlati Filozófia Iskolája Alapítvány",
  orgAddress: {
    label: "Cím",
    zipCode: 2113,
    city: "Erdőkertes",
    street: "Pálya utca",
    houseNum: 6,
    floor: "földszint",
    doorNum: null,
    fullAddress: function () {
      return `${this.zipCode} ${this.city}, ${this.street} ${this.houseNum}.`;
    },
  },
  contactInfo: [
    { label: "Mobil", data: "+36 30 242 62 03" },
    { label: "E-mail", data: "programszervezes@filozofia.hu" },
  ],
  legalInfo: [
    { label: "Adószám", data: "18111514-1-43" },
    { label: "Bankszámlaszám",
      data: "CIB Bank Zrt. 10700079-27807408-51100005",
    },
  ],
};
