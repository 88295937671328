import React from "react";
import Thinker from "../assets/images/other/Musée_Rodin_1.jpg"

function NotFound404() {
  return (
    <div className="page-wrapper">
      <h1>404 - Oldal nem található</h1>
      <h2>Biztosan ezt kerested?</h2>
      <img 
        src={Thinker}
        alt="Auguste Rodin - Le Penseur Biztos ezt kerested? Attribution: CrisNYCa, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons"
      />
    </div>
  );
}
export default NotFound404;
