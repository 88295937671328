import React from "react";
import SchoolInfo from "./reuseable/SchoolInfo";
import "../styles/global-styles.css";
import "../styles/contact.css";
import LaCarta from "../assets/images/other/La_carta.jpeg"
import HelmetMetaData from "./HelmetMetaData";

function Contact() {
  const uniqueHelmetMetaData = {
    title: "Gyakorlati Filozófia Iskolája | Kapcsolat",
    description:
      "Gyakorlati Filozófia Iskolája kapcsolat. Cím, Mobil, E-mail, Adószám, Bankszámlaszám.",
  };

  return (
    <div className="page-wrapper">
      <HelmetMetaData {...uniqueHelmetMetaData} />
      <div>
        <h1>Kapcsolat</h1>
      </div>
      <div className="inside-wrapper">
        <SchoolInfo placement="contact" />
        <div className="contact-image-wrapper">
        <img 
        src={LaCarta}
        alt="La Carta, Joan Llimona, festménye. Attribution: Joan Llimona, Public domain, via Wikimedia Commons"
      />
        </div>
      </div>
    </div>
  );
}

export default Contact;
