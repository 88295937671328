import React from "react";
import CoursesIntro from "./CoursesIntro";
import CourseCard from "./CourseCard";
import HelmetMetaData from "../HelmetMetaData";

function Courses() {
  const uniqueHelmetMetaData = {
    title: "Gyakorlati Filozófia Iskolája | Kurzusok",
    description:
      "A Gyakorlati Filozófia Iskolája kurzusai, tanfolyamaink felépítése, témakörök, aktív tanfolyamunk.",
  };

  return (
    <div className="page-wrapper">
      <HelmetMetaData {...uniqueHelmetMetaData} />
      <CoursesIntro />
      <CourseCard />
    </div>
  );
}

export default Courses;
